import ApolloClient from 'apollo-boost'
import fetch from 'isomorphic-fetch'
import {
  IntrospectionFragmentMatcher,
  InMemoryCache,
} from 'apollo-cache-inmemory'

import introspectionQueryResultData from './fragment-types'

const cache = new InMemoryCache({
  fragmentMatcher: new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  }),
})

export const client = new ApolloClient({
  uri: process.env.GATSBY_APP_GRAPHQL || 'https://graphql.foodsteps.no/graphql',
  fetch,
  cache,
  async request(operation) {
    if (typeof window === 'undefined') {
      return
    }

    const token = window.sessionStorage.getItem('token')
    if (!token) {
      return
    }

    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  },
})
