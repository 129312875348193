const get = require('lodash/get')

const { COLORS } = require('./src/lib/colors')

exports.onPreRouteUpdate = ({ location, prevLocation }) => {
  const scrollPos =
    window.pageYOffset ||
    document.body.scrollTop ||
    document.documentElement.scrollTop
  const root = document.documentElement
  root.style.setProperty('--scroll-pos', `-${scrollPos}px`)
}

const ROUTE_COLORS = {
  '/': COLORS.red,
  '/404': COLORS.blue,
  '/gavekort': COLORS.orange,
  '/gavekort/kjop': COLORS.red,
  '/gavekort/vipps': COLORS.red,
  '/gavekort/los-inn': COLORS.red,
  '/registrer': COLORS.blue,
  '/kurs': COLORS.yellow,
  '/premium': COLORS.yellow,
  '/oppskrifter': COLORS.yellow,
  '/last-ned': COLORS.yellow,
  '/om': COLORS.blue,
  '/personvern': COLORS.blue,
  '/vilkar': COLORS.blue,
  default: COLORS.red,
}

const getPathColor = location => {
  const pathname = get(location, 'pathname')
  const hash = get(location, 'hash')

  if (!pathname) {
    return ROUTE_COLORS.default
  }

  const path = pathname.length > 1 ? pathname.replace(/\/$/, '') : pathname
  const colors = ROUTE_COLORS[path] || ROUTE_COLORS.default

  // Plain color array
  if (Array.isArray(colors)) {
    return colors
  }

  // Different colors for different hashes
  return colors[hash] || colors.default
}

exports.onRouteUpdate = ({ location, prevLocation }) => {
  const colors = getPathColor(location)
  const root = document.documentElement
  document.body.classList.remove('dark')

  colors.forEach(([key, value]) => {
    root.style.setProperty(`--color-${key}`, value)
  })

  const gatsbyWrapper = document.getElementById('gatsby-focus-wrapper')

  if (gatsbyWrapper) {
    gatsbyWrapper.focus()
    gatsbyWrapper.setAttribute('aria-live', 'polite')
  }

  if (prevLocation) {
    const prevColors = getPathColor(prevLocation)
    prevColors.forEach(([key, value]) => {
      root.style.setProperty(`--prev-color-${key}`, value)
    })
  }
}

exports.wrapRootElement = require('./src/apollo/wrap-root-element').default
