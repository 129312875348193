import '~styles/styles.scss'
import './index.scss'

import React from 'react'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import PageTransition from '../PageTransition'
import Header from '../Header'
import Footer from '../Footer'

const bem = new BEMHelper('layout')

const HIDE_HEADER = {
  '/registrer': true,
  '/registrer-vipps': true,
  '/gavekort/kjop': true,
  '/gavekort/vipps': true,
  '/gavekort/los-inn': true,
  '/gavekort/takk': true,
  '/gavekort/feil': true,
  '/gavekort/last-ned': true,
  // '/admin': true,
  '/admin/login': true,
  '/admin/rapporter': true,
}

export default function Layout({ children, location }) {
  const { pathname } = location
  const hideHeader = HIDE_HEADER[pathname.replace(/\/$/, '')] || false

  return (
    <div className="website">
      <Header hide={hideHeader} location={location} />

      <main {...bem('')}>
        <PageTransition pathname={pathname} className="page">
          {children}
        </PageTransition>
      </main>

      {!hideHeader && <Footer />}
    </div>
  )
}

Layout.propTypes = {
  children: T.node.isRequired,
  location: T.object.isRequired,
}
