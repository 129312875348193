import './index.scss'

import React from 'react'
import { Link } from 'gatsby'
// import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import {
  DOWNLOAD_FROM_APPLE,
  DOWNLOAD_FROM_GOOGLE,
} from '../../data/download-links'
import { Logo, Container } from '@components'

const bem = new BEMHelper('footer')

const CONTACT_LINKS = [
  ['post@foodsteps.no', 'mailto:post@foodsteps.no'],
  ['(+47) 922 31 200', 'tel:+4792231200'],
]

const SOME_LINKS = [
  ['AppStore', DOWNLOAD_FROM_APPLE],
  ['Google Play', DOWNLOAD_FROM_GOOGLE],
  ['Facebook', 'https://www.facebook.com/foodstepsapp/'],
  ['Instagram', 'https://www.instagram.com/foodsteps_app/'],
]

const MUTED_LINKS = [
  ['Vilkår', '/vilkar'],
  ['Personvern', '/personvern'],
]

export default function Footer() {
  return (
    <footer {...bem('')}>
      <Container node="div" {...bem('content')}>
        <div {...bem('section')}>
          <Link to="/" {...bem('logo')} aria-label="Forsiden">
            <Logo />
          </Link>
        </div>
        <div {...bem('section')}>
          <Link to="/om" {...bem('link')}>
            Om Foodsteps
          </Link>
          <Link to="/artikler" {...bem('link')}>
            Artikler
          </Link>

          {MUTED_LINKS.map(([label, to]) => (
            <Link key={to} to={to} {...bem('link')}>
              {label}
            </Link>
          ))}
        </div>
        <div {...bem('section')}>
          {CONTACT_LINKS.map(([label, link]) => (
            <a
              key={link}
              href={link}
              {...bem('link')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {label}
            </a>
          ))}
          <a
            href="https://goo.gl/maps/vKDtPhicvjkSjmxv7"
            {...bem('link')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Kittel-Nielsens vei 61 B</span>
            <span {...bem('postal')}>1163 Oslo</span>
          </a>
        </div>

        <div {...bem('section', 'bottom')}>
          {SOME_LINKS.map(([label, link]) => (
            <a
              key={link}
              href={link}
              {...bem('muted')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {label}
            </a>
          ))}
        </div>
      </Container>
    </footer>
  )
}
