export default {
  __schema: {
    types: [
      {
        kind: 'INTERFACE',
        name: 'MuxTrack',
        possibleTypes: [
          { name: 'MuxVideoTrack' },
          { name: 'MuxAudioTrack' },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'PageSection',
        possibleTypes: [
          { name: 'WeekMenuPromotion' },
          { name: 'CategoryPromotion' },
          { name: 'RecipePromotion' },
          { name: 'CategoryList' },
          { name: 'CoursesList' },
        ],
      },
      {
        kind: 'INTERFACE',
        name: 'Payment',
        possibleTypes: [
          { name: 'VippsPayment' },
          { name: 'InvoicePayment' },
        ],
      },
    ],
  },
}
