module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Foodsteps","short_name":"Foodsteps","background_color":"#ffffff","display":"standalone","orientation":"portrait","start_url":"/","splash_pages":null,"icon":"/home/node/app/src/assets/images/icons/icon-192x192.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9b47cb80b976899e7802bebacb4725d8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/node/app/src/components/Layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MNRWXFL","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"includeInDevelopment":false,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
