// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-brukere-js": () => import("./../../../src/pages/admin/brukere.js" /* webpackChunkName: "component---src-pages-admin-brukere-js" */),
  "component---src-pages-admin-fakturaer-js": () => import("./../../../src/pages/admin/fakturaer.js" /* webpackChunkName: "component---src-pages-admin-fakturaer-js" */),
  "component---src-pages-admin-gavekort-index-js": () => import("./../../../src/pages/admin/gavekort/index.js" /* webpackChunkName: "component---src-pages-admin-gavekort-index-js" */),
  "component---src-pages-admin-gavekort-lag-faktura-js": () => import("./../../../src/pages/admin/gavekort/lag-faktura.js" /* webpackChunkName: "component---src-pages-admin-gavekort-lag-faktura-js" */),
  "component---src-pages-admin-gavekort-lag-flerbruk-js": () => import("./../../../src/pages/admin/gavekort/lag-flerbruk.js" /* webpackChunkName: "component---src-pages-admin-gavekort-lag-flerbruk-js" */),
  "component---src-pages-admin-gavekort-sok-js": () => import("./../../../src/pages/admin/gavekort/sok.js" /* webpackChunkName: "component---src-pages-admin-gavekort-sok-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-login-js": () => import("./../../../src/pages/admin/login.js" /* webpackChunkName: "component---src-pages-admin-login-js" */),
  "component---src-pages-admin-rapporter-js": () => import("./../../../src/pages/admin/rapporter.js" /* webpackChunkName: "component---src-pages-admin-rapporter-js" */),
  "component---src-pages-admin-statistikk-js": () => import("./../../../src/pages/admin/statistikk.js" /* webpackChunkName: "component---src-pages-admin-statistikk-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-artikler-index-js": () => import("./../../../src/pages/artikler/index.js" /* webpackChunkName: "component---src-pages-artikler-index-js" */),
  "component---src-pages-gavekort-feil-js": () => import("./../../../src/pages/gavekort/feil.js" /* webpackChunkName: "component---src-pages-gavekort-feil-js" */),
  "component---src-pages-gavekort-index-js": () => import("./../../../src/pages/gavekort/index.js" /* webpackChunkName: "component---src-pages-gavekort-index-js" */),
  "component---src-pages-gavekort-kjop-js": () => import("./../../../src/pages/gavekort/kjop.js" /* webpackChunkName: "component---src-pages-gavekort-kjop-js" */),
  "component---src-pages-gavekort-last-ned-js": () => import("./../../../src/pages/gavekort/last-ned.js" /* webpackChunkName: "component---src-pages-gavekort-last-ned-js" */),
  "component---src-pages-gavekort-los-inn-js": () => import("./../../../src/pages/gavekort/los-inn.js" /* webpackChunkName: "component---src-pages-gavekort-los-inn-js" */),
  "component---src-pages-gavekort-takk-js": () => import("./../../../src/pages/gavekort/takk.js" /* webpackChunkName: "component---src-pages-gavekort-takk-js" */),
  "component---src-pages-gavekort-vipps-js": () => import("./../../../src/pages/gavekort/vipps.js" /* webpackChunkName: "component---src-pages-gavekort-vipps-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kurs-js": () => import("./../../../src/pages/kurs.js" /* webpackChunkName: "component---src-pages-kurs-js" */),
  "component---src-pages-last-ned-js": () => import("./../../../src/pages/last-ned.js" /* webpackChunkName: "component---src-pages-last-ned-js" */),
  "component---src-pages-om-js": () => import("./../../../src/pages/om.js" /* webpackChunkName: "component---src-pages-om-js" */),
  "component---src-pages-oppskrifter-js": () => import("./../../../src/pages/oppskrifter.js" /* webpackChunkName: "component---src-pages-oppskrifter-js" */),
  "component---src-pages-personvern-js": () => import("./../../../src/pages/personvern.js" /* webpackChunkName: "component---src-pages-personvern-js" */),
  "component---src-pages-registrer-js": () => import("./../../../src/pages/registrer.js" /* webpackChunkName: "component---src-pages-registrer-js" */),
  "component---src-pages-vilkar-js": () => import("./../../../src/pages/vilkar.js" /* webpackChunkName: "component---src-pages-vilkar-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

