import './index.scss'

import React from 'react'
import T from 'prop-types'
import { Transition, TransitionGroup } from 'react-transition-group'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('page')

export default function PageTransition({
  children,
  pathname,
  enter,
  exit,
  node,
}) {
  const Node = node

  return (
    <TransitionGroup component={null}>
      <Transition
        key={pathname}
        timeout={{ enter, exit }}
        unmountOnExit={true}
        mountOnEnter={true}
      >
        {status => {
          return <Node {...bem('', status)}>{children}</Node>
        }}
      </Transition>
    </TransitionGroup>
  )
}

PageTransition.propTypes = {
  children: T.any.isRequired,
  pathname: T.string.isRequired,
  enter: T.number,
  exit: T.number,
  node: T.string,
}

PageTransition.defaultProps = {
  enter: 1200,
  exit: 600,
  node: 'div',
}
