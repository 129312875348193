/* eslint-disable react-hooks/exhaustive-deps */
import './index.scss'

import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import T from 'prop-types'
import BEMHelper from 'react-bem-helper'

import { Logo, Container, Icon } from '@components'

const bem = new BEMHelper('header')

export default function Header({ hide, location }) {
  const [expanded, setExpanded] = useState(false)
  const hiddenProps = hide ? { tabIndex: '-1' } : null

  const toggleMenu = () => {
    setExpanded(expanded => !expanded)
  }

  // Listen for route change and close if menu is open
  useEffect(() => {
    if (expanded) {
      setExpanded(false)
      document.body.classList.remove('modal-open')
    }
  }, [location.pathname])

  useEffect(() => {
    if (expanded) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }

    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [expanded])

  return (
    <nav {...bem('', { hide, expanded })} {...hiddenProps}>
      <Container node="div" {...bem('content')}>
        <div {...bem('main')}>
          <Link
            to="/last-ned"
            {...bem('download')}
            aria-label="Last ned"
            {...hiddenProps}
          >
            <Icon icon="download" />
          </Link>

          <Link to="/" {...bem('logo')} aria-label="Forsiden" {...hiddenProps}>
            <Logo />
          </Link>

          <button
            type="button"
            {...bem('menu-toggle')}
            aria-label="Meny"
            {...hiddenProps}
            onClick={toggleMenu}
          >
            <Icon icon="hamburger" />
          </button>
        </div>

        <div {...bem('links', { expanded })}>
          {/* <Link to="/registrer" {...bem('link')} {...hiddenProps}>
            Registrer deg
          </Link> */}
          {/* <Link to="/gavekort" {...bem('link')} {...hiddenProps}>
            Gavekort
          </Link> */}
          <Link to="/last-ned" {...bem('link')} {...hiddenProps}>
            Last ned appen
          </Link>

          <button
            type="button"
            {...bem('close')}
            onClick={toggleMenu}
            aria-label="Lukk meny"
          >
            <Icon icon="close" {...bem('close-icon')} />
          </button>
        </div>
      </Container>
    </nav>
  )
}

Header.propTypes = {
  hide: T.bool,
  location: T.object,
}
